// eslint-disable-next-line import/no-extraneous-dependencies
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['dialogContainer']);

export const CalendlyModalStyles = helpers.defineMultiStyleConfig({
  baseStyle: {
    dialogContainer: {
      p: '64px 0',
    },
  },
});
