const spacing = {
  space: {
    container: {
      sm: '24px',
      md: '28px',
      lg: '44px',
      '2xl': '120px',
    },
    header: {
      sm: '20px',
      lg: '24px',
    },
    hero: {
      top: {
        sm: '140px',
        md: '180px',
        lg: '260px',
        xl: '140px',
      },
      bottom: {
        sm: '60px',
        md: '80px',
        lg: '100px',
        xl: '120px',
      },
    },
    offer: {
      top: {
        sm: '60px',
        md: '80px',
        lg: '100px',
        xl: '120px',
      },
      bottom: {
        sm: '40px',
        xl: '46px',
      },
    },
    offerItem: {
      sm: '28px',
      md: '40px',
      lg: '60px',
    },
    section: {
      sm: '60px',
      md: '80px',
      lg: '100px',
    },
    sectionTitle: {
      sm: '24px',
      md: '40px',
      lg: '60px',
      xl: '64px',
    },
    cases: {
      sm: '20px',
      lg: '32px',
    },
    button: {
      padding: {
        sm: '24px',
        lg: '40px',
      },
      gap: {
        sm: '8px',
        lg: '12px',
      },
    },
    card: {
      padding: {
        sm: '16px',
        lg: '36px',
        '2xl': '44px',
      },
      gap: {
        sm: '16px',
        lg: '28px',
        xl: '32px',
        '2xl': '80px',
      },
    },
    message: {
      gap: '24px',
      margin: '5px',
    },
    socials: '32px',
    footer: '24px',
  },
};

export default spacing;
