const colors = {
  primary: '#121214',
  secondary: '#FFFFFF',
  grey: {
    light: '#F4F4F4',
    primary: '#EAEAEA',
    secondary: '#80838F',
    tertiary: '#848484',
    additional: '#999999',
    400: 'rgba(128, 131, 143, 0.4)',
  },
  hover: {
    grey: '#292A2E',
  },
  accent: {
    green: '#00D6A8',
    blue: {
      light: '#5FA3E6',
      dark: '#4580C6',
      darkness: '#2461FF',
    },
    purple: '#9767FF',
    orange: '#F89C6D',
  },
  overlay: 'rgba(18, 18, 20, .6)',
};

export default colors;
