import { defineStyleConfig } from '@chakra-ui/react';

const ButtonStyle = defineStyleConfig({
  baseStyle: {
    borderRadius: 0,
    cursor: 'pointer',
    textTransform: 'uppercase',
    lineHeight: 'base',
    transition: '.3s ease-in',
    path: {
      transition: '.3s ease-in',
    },
  },
  sizes: {
    sm: {
      pl: 'button.padding.sm',
      pr: 'button.padding.sm',
      h: '44px',
      fontSize: 'md',
      fontWeight: 'regular',
      letterSpacing: '.02em',
    },
    lg: {
      pl: 'button.padding.lg',
      pr: 'button.padding.lg',
      h: '64px',
      fontSize: 'xl',
      fontWeight: 'medium',
      letterSpacing: '.04em',
    },
  },
  variants: {
    solid: {
      bgColor: 'primary',
      color: 'secondary',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '.chakra-button__icon': {
        ml: '10px',
        w: '10px',
        mr: '-4px',
      },
      svg: {
        w: '10px',
        h: '10px',
      },
      _hover: {
        bg: 'accent.blue.darkness',
      },
      _active: {
        bg: 'accent.blue.darkness',
      },
    },
    outline: {
      border: '1px solid',
      borderColor: 'grey.secondary',
      _hover: {
        bg: 'none',
        borderColor: 'accent.blue.darkness',
        color: 'accent.blue.darkness',
        path: {
          stroke: 'accent.blue.darkness',
        },
      },
      _active: {
        bg: 'none',
        borderColor: 'accent.blue.darkness',
        color: 'accent.blue.darkness',
      },
    },
  },
  defaultProps: {
    size: 'sm',
  },
});

export default ButtonStyle;
