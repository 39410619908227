const sizes = {
  container: {
    sm: '360px',
    md: '480px',
    lg: '768px',
    xl: '1024px',
    '2xl': '1440px',
  },
};

export default sizes;
