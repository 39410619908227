const typography = {
  fontSizes: {
    xxs: '11px',
    xs: '13px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '30px',
    '5xl': '32px',
    xxl: '36px',
    '2xxl': '44px',
    '3xxl': '48px',
    '4xxl': '50px',
    '5xxl': '78px',
    '6xxl': '84px',
  },
  lineHeights: {
    normal: 'normal',
    shorter: '20px',
    short: '28px',
    base: '24px',
    tall: '84px',
    taller: '92px',
    '3': '28px',
    '4': '30px',
    '5': '32px',
    '6': '36px',
    '7': '40px',
    '8': '42px',
    '9': '50px',
    '10': '54px',
    '11': '58px',
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    bold: 700,
  },
};

export default typography;
