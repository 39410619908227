import { defineStyleConfig } from '@chakra-ui/react';
import dashed from '../../../assets/divider_dashed.svg';

const dashedImage: string = dashed;
const DividerStyle = defineStyleConfig({
  baseStyle: {
    opacity: '1',
    boxSizing: 'border-box',
    ml: 'auto',
    mr: 'auto',
  },
  variants: {
    solid: {
      borderColor: 'primary',
    },
    dashed: {
      maxW: {
        base: 'calc(100% - 56px)',
        lg: '680px',
        xl: '936px',
        '2xl': '1200px',
      },
      borderBottomWidth: '0',
      borderColor: 'transparent',
      height: '10px',
      position: 'relative',
      _before: {
        content: '""',
        bg: `url('${dashedImage}')`,
        bgRepeat: 'repeat-x',
        position: 'absolute',
        left: '0',
        right: '0',
        height: '10px',
      },
    },
  },
  defaultProps: {
    variant: 'solid',
  },
});

export default DividerStyle;
