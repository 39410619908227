const textStyles = {
  h1: {
    fontFamily: 'body',
    fontSize: {
      base: '2xxl',
      md: '4xxl',
      lg: '5xxl',
      xl: '6xxl',
    },
    fontWeight: 'medium',
    lineHeight: {
      base: '9',
      md: '11',
      lg: 'tall',
      xl: 'taller',
    },
    letterSpacing: '0',
  },
  h2: {
    fontFamily: 'body',
    fontSize: {
      base: 'xxl',
      md: '2xxl',
      lg: '3xxl',
    },
    fontWeight: 'medium',
    lineHeight: {
      base: '8',
      md: '9',
      lg: '10',
    },
    letterSpacing: '0',
  },
  h3: {
    fontFamily: 'body',
    fontSize: {
      base: '3xl',
      md: '5xl',
      lg: 'xxl',
    },
    fontWeight: 'medium',
    lineHeight: {
      base: '5',
      md: '6',
      lg: '7',
    },
    letterSpacing: '0',
  },
  h4: {
    fontFamily: 'body',
    fontSize: {
      base: '2xl',
      md: '3xl',
    },
    fontWeight: 'medium',
    lineHeight: {
      base: '4',
      md: '6',
    },
    letterSpacing: '0',
  },
  body1: {
    fontFamily: 'body',
    fontSize: {
      base: 'lg',
      md: 'xl',
    },
    fontWeight: 'regular',
    lineHeight: {
      base: 'short',
      md: '4',
    },
    letterSpacing: '0',
  },
  body2: {
    fontFamily: 'body',
    fontSize: {
      base: 'md',
      md: 'lg',
    },
    fontWeight: 'regular',
    lineHeight: {
      base: 'base',
      md: 'short',
    },
    letterSpacing: '0',
  },
  body3: {
    fontFamily: 'body',
    fontSize: {
      base: 'sm',
      md: 'md',
    },
    fontWeight: 'regular',
    lineHeight: {
      base: 'shorter',
      md: 'base',
    },
    letterSpacing: '0',
  },
  body4: {
    fontFamily: 'body',
    fontSize: 'sm',
    fontWeight: 'regular',
    lineHeight: 'shorter',
    letterSpacing: '0',
  },
  body5: {
    fontFamily: 'body',
    fontSize: 'xs',
    fontWeight: 'regular',
    lineHeight: 'normal',
    letterSpacing: '0',
  },
  body6: {
    fontFamily: 'body',
    fontSize: 'xxs',
    fontWeight: 'regular',
    lineHeight: 'normal',
    letterSpacing: '0',
  },
  linkXl: {
    fontFamily: 'body',
    fontSize: 'xxl',
    lineHeight: '8',
    fontWeight: 'regular',
    letterSpacing: '.02em',
  },
  linkLg: {
    fontFamily: 'body',
    fontSize: 'md',
    lineHeight: 'base',
    fontWeight: 'regular',
    letterSpacing: '.02em',
  },
  linkMd: {
    fontFamily: 'body',
    fontSize: 'xl',
    lineHeight: 'base',
    fontWeight: '500',
    letterSpacing: '0.04em',
  },
  linkSm: {
    fontFamily: 'body',
    fontSize: 'sm',
    lineHeight: 'shorter',
    fontWeight: 'regular',
    letterSpacing: '0',
  },
};

export type TextStyles = typeof textStyles;

export default textStyles;
