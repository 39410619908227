import { defineStyleConfig } from '@chakra-ui/react';

const LinkStyles = defineStyleConfig({
  baseStyle: {
    textTransform: 'uppercase',
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    _before: {
      content: '""',
      position: 'absolute',
      bottom: '-1px',
      left: 0,
      w: 0,
      height: '1px',
      bgColor: 'primary',
      transition: '.25s ease-in',
    },
    _hover: {
      textDecoration: 'none',
      _before: {
        w: '100%',
      },
    },
  },
  sizes: {
    sm: {
      textStyle: 'linkSm',
    },
    lg: {
      textStyle: 'linkLg',
    },
    xl: {
      textStyle: 'linkXl',
      _before: {
        bottom: '-6px',
        h: '2px',
      },
    },
  },
  variants: {
    icon: {
      _before: {
        left: '40px',
      },
      _hover: {
        _before: {
          w: 'calc(100% - 40px)',
        },
      },
    },
    secondary: {
      color: 'accent.blue.darkness',
      _before: {
        bgColor: 'accent.blue.darkness',
        w: 'calc(100% - 18px)',
      },
      _hover: {
        opacity: '.8',
        _before: {
          w: '0',
        },
      },
    },
  },
  defaultProps: {
    size: 'lg',
  },
});

export default LinkStyles;
