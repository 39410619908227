import { defineStyleConfig } from '@chakra-ui/react';

const ContainerStyles = defineStyleConfig({
  baseStyle: {
    maxW: {
      md: '100%',
      lg: 'container.lg',
      xl: 'container.xl',
      '2xl': 'container.2xl',
    },
    pl: {
      base: 'container.sm',
      md: 'container.md',
      lg: 'container.lg',
      '2xl': 'container.2xl',
    },
    pr: {
      base: 'container.sm',
      md: 'container.md',
      lg: 'container.lg',
      '2xl': 'container.2xl',
    },
  },
});

export default ContainerStyles;
